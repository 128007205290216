import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import ServiceHeroSmall from "../../components/service-hero-small";
import ServiceCard from "../../components/service-card";
import Faq from "../../components/faq";
import Reviews from "../../components/reviews";
import CtaBanner from "../../components/cta-banner";
import Navigation from "../../components/navigation";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import SVGZoomDiscovery from "../../components/SVG/zoom-discovery-svg";
import SVGIdentify from "../../components/SVG/identify-svg";
import SVGContentCreated from "../../components/SVG/content-created-svg";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";

const CopywritingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Info-Panel-Image-Tall" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			socialImg: wpMediaItem(title: { eq: "Social-Media-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			websiteCopyImg: wpMediaItem(title: { eq: "Website-Copy-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			blogWritingImg: wpMediaItem(title: { eq: "Blog-Writing-Image-1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			whoImg: wpMediaItem(title: { eq: "Why-Choose-ARC" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			advertisingImg: wpMediaItem(title: { eq: "Advertising-Copy" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			whatTypeImg: wpMediaItem(title: { eq: "Translation-Hero" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			whatsItImg: wpMediaItem(title: { eq: "Copycoaching-Hero" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			digitalImg: wpMediaItem(title: { eq: "Digital-Content" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			blog2Img: wpMediaItem(title: { eq: "Blog-Writing-Image-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const faqs = [
		{
			question:
				"Why should I choose ARC Writing and Translation Services to write my copy?",
			answer:
				"Because having your copy written by a professional writer who knows how to strike the right tone for your business will make your business more successful by creating the right impression with your existing and potential customers, leaving you free to focus on what you do best: running your business",
		},
		{
			question:
				"How do I know ARC Writing and Translation Services will write the best copy for me?",
			answer:
				"Once you have approached ARC Writing and Translation Services to provide you with a quote a meeting will be set up, face-to-face, online – via Zoom or an equivalent platform – or over the phone, to discuss your requirements and get a deeper understanding of your business.",
		},
		{
			question:
				"How will ARC Writing and Translations Services know how my industry works and what copy works best for me business?",
			answer:
				"Once an initial meeting has been set up, ARC Writing and Translation Services will undertake the relevant industry-specific research to identify the correct tone and terminology for your business and find out who your target customers are so that the copy delivered is tailored for the right audience.",
		},
		{
			question: "How much will it cost?",
			answer:
				"All copywriting projects involve different amounts of research and writing time, so it’s not a case of one size fits all. Once you get in touch and outline your needs, a price structure can be determined.",
		},

		{
			question: "How long will it take to deliver the copy?",
			answer:
				"Delivery will depend on the scope of the project and will be discussed upon initial contact. Urgent needs can be accommodated and rush rates applied. The version delivered is never the first draft, so time is needed to write at least three drafts to arrive at the perfect final draft.",
		},

		{
			question:
				"Is it possible to request revisions of the work and, if so, how many?",
			answer:
				"Yes, absolutely. Up to 2 revisions will be provided as standard. Any further changes may be subject to an extra fee.",
		},
	];
	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const socialImg = data.socialImg?.localFile.childImageSharp.gatsbyImageData;
	const websiteCopyImg =
		data.websiteCopyImg?.localFile.childImageSharp.gatsbyImageData;

	const blogWritingImg =
		data.blogWritingImg?.localFile.childImageSharp.gatsbyImageData;

	const advertisingImg =
		data.advertisingImg?.localFile.childImageSharp.gatsbyImageData;

	const digitalImg = data.digitalImg?.localFile.childImageSharp.gatsbyImageData;
	const blog2Img = data.blog2Img?.localFile.childImageSharp.gatsbyImageData;
	const whoImg = data.whoImg?.localFile.childImageSharp.gatsbyImageData;
	const whatTypeImg =
		data.whatTypeImg?.localFile.childImageSharp.gatsbyImageData;
	const whatsItImg = data.whatsItImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Copywriting London",
				item: {
					url: `${siteUrl}/services/aesthetics-copywriter`,
					id: `${siteUrl}/services/aesthetics-copywriter`,
				},
			},
		],
	};

	return (
		<Layout>
			<Navigation />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Aesthetics Copywriter | Professional Copy For Clinics"
				description="Professionally written copy for clinics offering medical aesthetic treatments."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/aesthetics-copywriter`,
					title: "Aesthetics Copywriter | Professionally written copy for clinics offering medical aesthetic treatments",
					description:
						"With my experience in writing for medical aesthetics - from anti-ageing creams to fillers - I can craft website copy that speaks to your brand",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/copywriting-services-scaled-1.jpg`,
							width: 1621,
							height: 2560,
							alt: "Copywriting services in London",
						},
					],
				}}
			/>
			<ServiceHeroSmall
				secondButtonText="Download my portfolio"
				secondButtonLink="https://arc-writing.rjmdigital.net/wp-content/uploads/2023/01/Antoinette-F-2023-1.pdf"
				secondButton={true}
				imgWidth="15rem"
				image={heroImg}
				imgAlt={data.heroImg.altText}
				title="Aesthetics Copywriter"
				subtitle="Writing copy for clinics offering medical aesthetic treatments"
				text={
					<>
						<p>
							Let me use my experience of writing content for many medical
							aesthetic treatments, from anti-ageing creams to skincare
							treatments and dermal fillers, to write your website copy.
						</p>
						<p>
							Whether you run a beauty salon or are an aesthetics practitioner
							who runs their own injectable fillers clinic, you need to be found
							on Google with relevant and engaging SEO-optimised copywriting.
						</p>
					</>
				}
			/>
			<section className="py-5 py-lg-7">
				<Container>
					<Row className="justify-content-center">
						<Col lg={10} xl={8} className="text-center">
							{" "}
							<h2 className="mb-5 underline d-none d-xl-block fs-1 ">
								Why hire an aesthetics copywriter?
							</h2>
							<h2 className="mb-5 d-xl-none fs-1 ">
								Why hire an aesthetics copywriter?
							</h2>
							<p>
								The best way to boost your online engagement is by ensuring that
								your marketing materials, from blogs to website content, are
								consistent with your brand's tone of voice, address your
								client's concerns and reassure them that you can meet their
								needs.
							</p>
							<p>
								{" "}
								My copywriting expertise comes from writing content for
								businesses that want to attract professional men and women with
								busy lives who are looking to find the right beauty salon for
								their needs online.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="position-relative py-5 py-lg-10">
				<div
					style={{ zIndex: -999999 }}
					className="w-100 h-100 start-0 top-0 position-absolute bg-light-background"
				></div>
				<Container style={{ zIndex: 1 }} className="position-relative ">
					<Row className="g-5">
						<Col lg={{ span: 5, order: "last" }}>
							<div
								style={{ width: "14rem", height: "12rem" }}
								className="bg-background d-lg-none position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "14rem", height: "12rem" }}
										image={whoImg}
										alt={data.whoImg.altText}
									/>
								</div>
							</div>
							<div
								style={{ width: "22rem", height: "17rem" }}
								className="bg-background  d-none d-lg-block d-xl-none position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "22rem", height: "17rem" }}
										image={whoImg}
										alt={data.whoImg.altText}
									/>
								</div>
							</div>
							<div
								style={{ width: "28rem", height: "23rem" }}
								className="bg-background  d-none d-xl-block position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "28rem", height: "23rem" }}
										image={whoImg}
										alt={data.whoImg.altText}
									/>
								</div>
							</div>
						</Col>
						<Col lg={7}>
							{" "}
							<h2 className="mb-4 underline d-none d-xl-inline-block fs-2 ">
								Copywriting services for the beauty industry
							</h2>
							<h2 className="mb-4 d-xl-none fs-1 ">
								Copywriting services for the beauty industry
							</h2>
							<p>
								From the very beginning, when you set up your business, you will
								have created your brand, probably starting with your company
								name and logo. Every online and printed word you have produced
								has contributed to your brand's voice.
							</p>
							<p>
								My job as a professional aesthetics copywriter is to make sure
								that all of your future written content is aligned with your
								values and is written in the same tone of voice so that it
								resonates with consumers, whether they are women or men and
								makes potential clients want to buy from you.
							</p>
							<h3 className="fs-4">Why work with me?</h3>
							<p>
								My more than 15 years of experience in writing content for
								product descriptions, blogs and beauty brands adds that extra
								dimension that your clients will love.
							</p>
							<h3 className="fs-4">Stand out from the competition</h3>
							<p>
								In a largely unregulated, increasingly popular market, standing
								out in the aesthetics industry by demonstrating your authority
								in the field and highlighting best practices is essential.
							</p>
							<Button
								className="cta-btn w-100 w-md-auto me-md-3 px-5 py-2"
								variant="secondary"
								as={Link}
								to="/contact"
							>
								Contact
							</Button>
							<Button
								className="cta-btn w-100 mt-3 mt-md-0  w-md-auto  px-5 py-2"
								variant="outline-secondary"
								target="_blank"
								rel="noreferrer"
								href="https://arc-writing.rjmdigital.net/wp-content/uploads/2023/01/Antoinette-F-2023-1.pdf"
								to="/contact"
							>
								Downlaod my portfolio
							</Button>
						</Col>
					</Row>
				</Container>
			</section>

			<Container className="text-center mt-5 ">
				<h2 className="mb-5 underline fs-1 ">Copywriting services</h2>
				<Row>
					<ServiceCard
						image={socialImg}
						imgAlt={data.socialImg.altText}
						title="Social media posts & profiles"
						text="Stay ahead of the game among your competitors with posts and articles written for you that generate engagement and cross-platform sharing. Free up more time to focus on other aspects of your business by outsourcing your social media to a professional copywriter."
					/>
					<ServiceCard
						image={websiteCopyImg}
						imgAlt={data.websiteCopyImg.altText}
						title="Website copy"
						text="Engaging copy that generates more sales by using the right words to persuade your customers to buy your products and/or services is essential to grow your business. Transform your website with original, persuasive copy by trusting an expert copywriter to keep your potential customers on-page long enough to secure a sale."
					/>
					<ServiceCard
						image={blogWritingImg}
						imgAlt={data.blogWritingImg.altText}
						title="Blog writing"
						text="Blog posts written that your ideal customers will want to read and that raise your profile online. The subject of your blog posts will be thoroughly researched before it is written to save you time. Share your blogs across all your social media platforms to engage with potential customers and increase sales."
					/>
					<ServiceCard
						image={advertisingImg}
						imgAlt={data.advertisingImg.altText}
						title="Advertising copy"
						text="Does your company use brochures, leaflets and catalogues to market its products and/or services? I can help you by writing copy that best promotes your products and services with the correct wording for your target audience and captivating language that draws the reader in and sells your product."
					/>
					<ServiceCard
						image={digitalImg}
						imgAlt={data.digitalImg.altText}
						title="Digital content"
						text="Whatever your copy requirements are to promote your business online, I can help you by crafting catchy taglines, straplines or by writing video scripts for use on various digital platforms."
					/>
					<ServiceCard
						image={blog2Img}
						imgAlt={data.blog2Img.altText}
						title="Business documents"
						text="Sales letters and/or company reports written that look professional and strike the right tone for your audience. Whether formal for external readers or more informal for an in-house audience, if this sounds like a service that would save you time, then please get in touch for a bespoke quote that meets your needs."
					/>
				</Row>
			</Container>
			<section className=" py-5 py-lg-8">
				<Container>
					<Row className="justify-content-between align-items-center gy-5 gx-7">
						<Col lg={4}>
							<GatsbyImage
								className="w-100"
								image={whatTypeImg}
								alt={data.whatTypeImg.altText}
							/>
						</Col>
						<Col lg={8}>
							{" "}
							<h2 className="mb-4 underline d-none d-xl-inline-block fs-1 ">
								Educate and inform prospects
							</h2>
							<h2 className="mb-4 d-xl-none fs-1 ">
								Educate and inform prospects
							</h2>
							<p>
								You also need to inform and educate your readers. With so many
								alternative treatments available cosmetic surgery is now often
								replaced with non-surgical interventions.
							</p>
							<p>
								As many treatments are relatively new and appeal to a younger
								audience, it is vital that you explain the product or services
								you offer in an accessible way.
							</p>
							<p>
								When someone takes the time out of their busy life to look for
								beauty salons or aesthetics practitioners on the internet, they
								want as much detail and advice about the treatment as possible.
							</p>
							<h3 className="fs-4">The power of storytelling</h3>
							<p>
								Consumers also buy from people, and in a very crowded
								marketplace, storytelling can be a valuable tool.
							</p>
							<p>
								I can use storytelling in your copywriting to tell the unique
								story of your business, its humble roots and how it now fits
								into the world of beauty.
							</p>
							<p>
								I take great pleasure in working with people in the medical
								aesthetics sector as I am familiar with the industry and can
								offer my expertise regarding writing website copy and helping
								ensure brand consistency.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="position-relative py-5 py-lg-8">
				<div
					style={{ zIndex: -999999 }}
					className="w-100 h-100 start-0 top-0 position-absolute bg-light-background"
				></div>
				<Container style={{ zIndex: 1 }} className="position-relative ">
					<Row className="justify-content-center">
						<Col className="text-center" xl={10}>
							{" "}
							<h2 className="mb-5 underline d-none d-xl-inline-block fs-1 ">
								Using the right words- why SEO matters
							</h2>
							<h2 className="mb-4 d-xl-none fs-1 ">
								Using the right words- why SEO matters
							</h2>
							<p>
								Words are powerful and have to be carefully selected. A
								potential client can be put off by the use of the wrong word,
								and if you don't use the right keywords on Google, they may
								never even find you!
							</p>
							<Button
								className="cta-btn w-100 w-md-auto me-md-3 mt-4 px-5 py-2"
								variant="secondary"
								as={Link}
								to="/contact"
							>
								Contact
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className=" py-5 py-lg-10">
				<Container>
					<Row className="align-items-center g-5">
						<Col lg={{ span: 5, order: "last" }}>
							<div
								style={{ width: "14rem", height: "12rem" }}
								className="bg-background d-lg-none position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "14rem", height: "12rem" }}
										image={whatsItImg}
										alt={data.whatsItImg.altText}
									/>
								</div>
							</div>
							<div
								style={{ width: "22rem", height: "17rem" }}
								className="bg-background  d-none d-lg-block d-xl-none position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "22rem", height: "17rem" }}
										image={whatsItImg}
										alt={data.whatsItImg.altText}
									/>
								</div>
							</div>
							<div
								style={{ width: "25rem", height: "20rem" }}
								className="bg-background  d-none d-xl-block position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "28rem", height: "23rem" }}
										image={whatsItImg}
										alt={data.whatsItImg.altText}
									/>
								</div>
							</div>
						</Col>
						<Col lg={7}>
							{" "}
							<h2 className="mb-4 underline d-none d-xl-inline-block fs-1 ">
								What’s it like working with me?
							</h2>
							<h2 className="mb-4 d-xl-none fs-1 ">
								What’s it like working with me?
							</h2>
							<p>
								As an aesthetics copywriter, gaining in-depth knowledge of your
								business is essential.
							</p>
							<p>
								My approach to all new aesthetics clients is first to get to
								know you and see if we are a good fit for each other and then
								find out about what you do and the clients you want to attract.
							</p>
							<Button
								className="cta-btn w-100 w-md-auto me-md-3 px-5 py-2"
								variant="secondary"
								as={Link}
								to="/contact"
							>
								Contact
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="position-relative mb-6  text-center">
				<div
					style={{ zIndex: "-3" }}
					className="position-absolute w-100 h-100 bg-light-background  "
				></div>
				<Container className="py-5">
					<Row>
						<Col>
							<h2 className="underline fs-1">Typical customer journey</h2>
							<p className="fs-5 mb-5  fw-light">
								You get two free rounds of review before signing off the project
							</p>
						</Col>
					</Row>
					<Row>
						<Col className="px-4" lg={4}>
							<SVGZoomDiscovery className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">Zoom discovery</h3>
							<p className="mb-5 ">
								We will have a FREE 30-minute Zoom discovery meeting during
								which we will discuss your requirements.
							</p>
						</Col>
						<Col className="px-4" lg={4}>
							<SVGIdentify className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">
								Identify your ideal client
							</h3>
							<p className="mb-5 ">
								We will have another Zoom meeting to identify your ideal client,
								establish the correct tone of voice and discuss the keywords for
								your on-page SEO and I will find out all about your business.
							</p>
						</Col>
						<Col className="px-4" lg={4}>
							<SVGContentCreated className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">Content created</h3>
							<p className="mb-5 ">
								I will create content that perfectly suits your needs using
								engaging copy that resonates with your target audience and is
								packed full of YOUR personality.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<Reviews description="I'm proud to say that I have several ongoing relationships with satisfied clients, as diverse as a business coach client and an estate agent. You can read my testimonials and Google reviews to find out more." />
			<Faq bottomNoMargin faqs={faqs} />
			<CtaBanner
				headline={
					<>
						I'd love to hear more about your
						<br className="d-none d-md-block" /> needs, and how I can help.
					</>
				}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
		</Layout>
	);
};

export default CopywritingPage;
